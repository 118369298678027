import { Component, NgZone } from '@angular/core';
import { PaymentsService } from '../../services/payments.service';
import { Observable, take, switchMap, tap, BehaviorSubject } from 'rxjs';
import { IPaidSubscription, IPlan, PaidSubscriptionsService, planSuggestions } from '../../services/paid-subscriptions.service';

enum loadingState {
  NotSet = 'NotSet',
  Updating = 'Updating',
  WaitingForUpdate = 'WaitingForUpdate',
  Ready = 'Ready'
}

@Component({
  selector: 'subscription-plan',
  templateUrl: './subscription-plan.component.html',
  styleUrls: ['./subscription-plan.component.css']
})
export class SubscriptionPlanComponent {
  protected usersSelected: number = 1;
  protected creditsSelected: number = 50;

  protected readonly minUsers = 1;
  protected readonly maxUsers = 30;

  protected readonly creditsStep = 10;
  protected readonly minCredits = 50;
  protected readonly maxCredits = 500;

  protected readonly planSuggestions = planSuggestions;

  protected selectedPlans$: Observable<IPaidSubscription[]> | undefined;
  protected usersProductPriceInCents$: Observable<number | null | undefined> | undefined;
  protected creditsProductPriceInCents$: Observable<number | null | undefined> | undefined;
  protected subscriptionLoading: BehaviorSubject<loadingState> = new BehaviorSubject<loadingState>(loadingState.NotSet);

  private unsubscribeFromSubscriptionUpdates: (() => void) | undefined;

  constructor(
    private paidSubscriptionsService: PaidSubscriptionsService,
    private paymentsService: PaymentsService,
    private ngZone: NgZone
  ) {
    this.usersProductPriceInCents$ = this.paidSubscriptionsService.usersProductPriceInCents$;
    this.creditsProductPriceInCents$ = this.paidSubscriptionsService.creditsProductPriceInCents$;
    this.selectedPlans$ = this.paidSubscriptionsService.selectedPlans$;
  }

  ngOnInit() {
    // Get Active plan once
    this.selectedPlans$
      ?.pipe(
        tap((selectedPlans) => {
          console.log('SubscriptionPlanComponent', 'selectedPlans', selectedPlans);
        }),
        take(1)
      )
      .subscribe((selectedPlans) => {
        if (selectedPlans.length > 0) {
          this.usersSelected = selectedPlans[0].users;
          this.creditsSelected = selectedPlans[0].credits;
        }
      });

    void this.paymentsService
      .onSubscriptionUpdate(() => {
        this.ngZone.run(() => {
          this.paidSubscriptionsService.refreshSubscriptions$.next();
          console.log('SubscriptionPlanComponent', 'Subscription Updated');
        });
        this.subscriptionLoading.next(loadingState.Ready);
      })
      .then((unsubscribe) => (this.unsubscribeFromSubscriptionUpdates = unsubscribe));
  }

  selectSuggestedSubscription(plan: IPlan) {
    this.usersSelected = plan.users;
    this.creditsSelected = plan.credits;
  }

  cancelPlan() {
    this.paidSubscriptionsService.goToOrganizationPortal()?.subscribe();
  }

  subscribeToPlan() {
    this.selectedPlans$
      ?.pipe(
        take(1),
        switchMap((selectedPlans) => {
          const userHasActivePlan = selectedPlans.length > 0;

          this.subscriptionLoading.next(userHasActivePlan ? loadingState.WaitingForUpdate : loadingState.Updating);

          return userHasActivePlan
            ? this.paidSubscriptionsService.updateSubscriptionSession(this.usersSelected, this.creditsSelected)
            : this.paidSubscriptionsService.startCheckoutSession(this.usersSelected, this.creditsSelected);
        })
      )
      .subscribe({
        error: () => {
          this.subscriptionLoading.next(loadingState.Ready);
        }
      });
  }

  ngOnDestroy() {
    this.unsubscribeFromSubscriptionUpdates?.();
  }

  protected readonly loadingState = loadingState;
}
