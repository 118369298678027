export enum EStatus {
  UPLOADING = "uploading",
  STORED = "stored",
  PROCESSING = "processing",
  COMPLETED = "completed",
  ERROR = "error",
  DELETED = "deleted",
  OUT_OF_CREDITS = "outOfCredits",
}

export enum EParsingStatus {
  PROCESSING = "processing",
  COMPLETED = "completed",
  ERROR = "error",
}

export enum EImageStatus {
  PROCESSING = "processing",
  COMPLETED = "completed",
  ERROR = "error",
}
