import { Component, OnInit } from "@angular/core";
import { CreditsApi } from "../../../api/credits.api";
import { Observable } from "rxjs";
import { ICredits } from "../../../../../../model/IOrganization";

@Component({
  selector: "credits",
  templateUrl: "./credits.component.html",
  styleUrls: ["./credits.component.css"],
})
export class CreditsComponent implements OnInit {
  availableCredits: number = 0;
  totalCredits: number = 0;
  creditsPackages$: Observable<ICredits[]> | undefined;

  constructor(private creditsApi: CreditsApi) {}

  calculateAvailableCredits(creditsPackages: ICredits[]) {
    this.availableCredits = 0;
    this.totalCredits = 0;
    creditsPackages.forEach((creditsPackage) => {
      this.availableCredits += creditsPackage.available;
      this.totalCredits += creditsPackage.total;
    });
  }

  ngOnInit() {
    this.creditsPackages$ = this.creditsApi.getAll([
      { field: "startDate", condition: "<=", value: new Date().getTime() / 1000 },
      { field: "endDate", condition: ">=", value: new Date().getTime() / 1000 },
    ]);
    this.creditsPackages$.subscribe((creditsPackages) => {
      this.calculateAvailableCredits(creditsPackages);
      console.log("CreditsComponent", "creditsPackages", creditsPackages);
    });
  }
}
