<div id="resumes" class="row fill-space overflow-hidden">

  <div id="filters" class="page-column column overflow-hidden">
    <div class="header row">
      <div class="fill-space column">
        <div class="title">Filters</div>
      </div>
    </div>
    <div class="gap-05 column" *ngIf="!resumeFiltersOptionsExist()">
      <div class="space-top-1"> No filter data. </div>
      <div class="empty-text">
        Filter data is automatically generated based on the uploaded resumes.
      </div>
    </div>
    <div class="filters-content column scrollbar">
      <ng-container *ngFor="let f of filtersService.resumeFilters">
        <filter-panel
            [filter]="f"
            (updateSelectedOptions)="filtersService.updateSelectedOptions($event)">
        </filter-panel>
      </ng-container>
    </div>
  </div>

  <div id="resumes-content" class="page-column column">

    <div class="header row">
      <div class="fill-space column">
        <div class="title">Resumes</div>
      </div>
    </div>

    <div class="space-top-bottom-1">
      <div class="search fill-space row space-bottom-1">
        <div class="search-icon"><img src="assets/images/search.svg" /></div>
        <div class="search-input fill-space">
          <input #searchInput type="text" placeholder="Enter search keyword and press enter..." (keydown.enter)="addSearchTag(searchInput)" class="fill-space" />
        </div>
      </div>
      <div class="refresh center"  (click)="refresh()"> <img src="assets/images/refresh.svg"/> </div>
    </div>

    <div class="filter-tags row row-center-left wrap">
      <ng-container *ngFor="let f of filtersService.resumeFilters">
        <tag *ngFor="let tag of f.selectedOptions" [text]="tag" [type]="f.name" (click)="filtersService.removeTag(f, tag)"></tag>
      </ng-container>
      <tag *ngFor="let tag of searchTags" [text]="tag" [type]="'Custom Search'" [className]="'custom'" (click)="removeSearchTag(tag)"></tag>
    </div>

    <!--<div class="resumes-list column scrollbar">
      <ng-container *ngIf="resumes$ | async as resumes; else loading">
        <ng-container *ngIf="resumes.length > 0; else empty">
          <a *ngFor="let resume of resumes" [routerLink]="[resume.id]" queryParamsHandling="preserve">
            <resume-panel [resume]="resume" [selectedResumeId]="selectedResumeId"></resume-panel>
          </a>
        </ng-container>
      </ng-container>
    </div>-->

    <cdk-virtual-scroll-viewport class="resumes-list column fill-space scrollbar" itemSize="160" minBufferPx="480" maxBufferPx="960">
      <ng-container *ngIf="resumes$ | async as resumes; else loading">
        <ng-container *ngIf="resumes.length > 0; else empty">
          <a *cdkVirtualFor="let resume of resumes; templateCacheSize: 0" [routerLink]="[resume.id]" queryParamsHandling="preserve">
            <resume-panel [resume]="resume" [selectedResumeId]="selectedResumeId"></resume-panel>
          </a>
        </ng-container>
      </ng-container>
    </cdk-virtual-scroll-viewport>


  </div>

  <div class="column fill-space overflow-hidden">
    <router-outlet></router-outlet>
  </div>

</div>

<ng-template #empty>
  <div class="empty column center">
    <div class="empty-icon"> <img src="assets/images/empty.svg"/> </div>
    <div class="empty-title"> No resumes  </div>
    <div class="empty-text" (click)="showUpload()">  <div class="button button-action"> Import Resumes </div>  </div>
  </div>
</ng-template>

<ng-template #loading>
  <div class="empty column center">
    <loader></loader>
  </div>
</ng-template>
