import { APP_INITIALIZER, ErrorHandler, isDevMode, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import * as Sentry from '@sentry/angular-ivy';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { connectFirestoreEmulator, getFirestore, provideFirestore } from '@angular/fire/firestore';
import { connectFunctionsEmulator, getFunctions, provideFunctions } from '@angular/fire/functions';
import { connectStorageEmulator, getStorage, provideStorage } from '@angular/fire/storage';
import { ContentComponent } from './common/content/content.component';
import { PageComponent } from './common/page/page.component';
import { BannerComponent } from './common/banner/banner.component';
import { LogoComponent } from './common/banner/logo/logo.component';
import { MainMenuComponent } from './common/banner/main-menu/main-menu.component';
import { ProfileComponent } from './common/banner/profile/profile.component';
import { UploadButtonComponent } from './common/banner/upload-button/upload-button.component';
import { LoginComponent } from './pages/login/login.component';
import { ResumesComponent } from './pages/resumes/resumes.component';
import { EmailVerificationComponent } from './pages/email-verification/email-verification.component';
import { CreditsComponent } from './common/banner/credits/credits.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { MatDialogModule } from '@angular/material/dialog';
import { JobsComponent } from './pages/jobs/jobs.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { LoaderComponent } from './common/loader/loader.component';
import { UserFormComponent } from './pages/users/user-form/user-form.component';
import { UserDeleteComponent } from './pages/users/user-delete/user-delete.component';
import { SettingsMenuComponent } from './pages/settings/settings-menu/settings-menu.component';
import { SettingsCompaniesComponent } from './pages/settings/settings-company/settings-companies.component';
import { SettingsAccountComponent } from './pages/settings/settings-account/settings-account.component';
import { CompanySubscriptionComponent } from './pages/settings/settings-subscription/company-subscription.component';
import { SubscriptionComponent } from './pages/settings/settings-subscription/subscription/subscription.component';
import { JobFormComponent } from './pages/jobs/job-form/job-form.component';
import { JobPanelComponent } from './pages/jobs/job-panel/job-panel.component';
import { JobComponent } from './pages/jobs/job/job.component';
import { JobApplicantComponent } from './pages/jobs/job/job-applicant/job-applicant.component';
import { JobApplicantCategoryComponent } from './pages/jobs/job/job-pages/job-applicants/job-applicant-category/job-applicant-category.component';
import { ProgressBarComponent } from './common/progress-bar/progress-bar.component';
import { UploadComponent } from './common/upload/upload.component';
import { UploadFileComponent } from './common/upload/upload-file/upload-file.component';
import { ResumePanelComponent } from './pages/resumes/resume-panel/resume-panel.component';
import { CheckboxComponent } from './common/checkbox/checkbox.component';
import { JobApplicantMatchComponent } from './pages/jobs/job/job-applicant/job-applicant-match/job-applicant-match.component';
import { JobMatchSkillsComponent } from './pages/jobs/job/job-applicant/job-applicant-match/job-match-skills/job-match-skills.component';
import { JobMatchExperienceComponent } from './pages/jobs/job/job-applicant/job-applicant-match/job-match-experience/job-match-experience.component';
import { JobMatchEducationComponent } from './pages/jobs/job/job-applicant/job-applicant-match/job-match-education/job-match-education.component';
import { ResumeComponent } from './pages/resumes/resume/resume.component';
import { ResumeInfoComponent } from './pages/resumes/resume/resume-info/resume-info.component';
import { ResumeContentComponent } from './pages/resumes/resume/resume-content/resume-content.component';
import { ResumeInsightsComponent } from './pages/resumes/resume/resume-insights/resume-insights.component';
import { RateBarComponent } from './common/rate-bar/rate-bar.component';
import { NgOptimizedImage } from '@angular/common';
import { ResumeOverviewComponent } from './pages/resumes/resume/resume-overview/resume-overview.component';
import { ResumeOverviewExperienceComponent } from './pages/resumes/resume/resume-overview/resume-overview-experience/resume-overview-experience.component';
import { ResumeOverviewEducationComponent } from './pages/resumes/resume/resume-overview/resume-overview-education/resume-overview-education.component';
import { ResumeOverviewTopSkillsComponent } from './pages/resumes/resume/resume-overview/resume-overview-top-skills/resume-overview-top-skills.component';
import { ResumeOverviewGapsComponent } from './pages/resumes/resume/resume-overview/resume-overview-gaps/resume-overview-gaps.component';
import { ResumeOverviewCareerComponent } from './pages/resumes/resume/resume-overview/resume-overview-career/resume-overview-career.component';
import { ProgressBarSegmentedComponent } from './common/progress-bar-segmented/progress-bar-segmented.component';
import { IndicatorInfoComponent } from './common/indicator-info/indicator-info.component';
import { LevelInfoComponent } from './common/level-info/level-info.component';
import { environment } from '../environments/environment';
import { ResumeContentExperienceComponent } from './pages/resumes/resume/resume-content/resume-content-experience/resume-content-experience.component';
import { ResumeContentEducationComponent } from './pages/resumes/resume/resume-content/resume-content-education/resume-content-education.component';
import { ResumeContentSkillsComponent } from './pages/resumes/resume/resume-content/resume-content-skills/resume-content-skills.component';
import { ResumeContentLanguagesComponent } from './pages/resumes/resume/resume-content/resume-content-languages/resume-content-languages.component';
import { ResumeContentLicensesAndCertificationsComponent } from './pages/resumes/resume/resume-content/resume-content-licenses-and-certifications/resume-content-licenses-and-certifications.component';
import { ResumeContentAwardsAndAchievementsComponent } from './pages/resumes/resume/resume-content/resume-content-awards-and-achievements/resume-content-awards-and-achievements.component';
import { ResumeContentProjectsComponent } from './pages/resumes/resume/resume-content/resume-content-projects/resume-content-projects.component';
import { ResumeContentAboutComponent } from './pages/resumes/resume/resume-content/resume-content-about/resume-content-about.component';
import { ResumeContentHobbiesAndInterestsComponent } from './pages/resumes/resume/resume-content/resume-content-hobbies-and-interests/resume-content-hobbies-and-interests.component';
import { ResumeContentPublicationsComponent } from './pages/resumes/resume/resume-content/resume-content-publications/resume-content-publications.component';
import { ResumeContentPresentationsAndConferencesComponent } from './pages/resumes/resume/resume-content/resume-content-presentations-and-conferences/resume-content-presentations-and-conferences.component';
import { ResumeOverviewRadarComponent } from './pages/resumes/resume/resume-overview/resume-overview-radar/resume-overview-radar.component';
import { NgChartsModule } from 'ng2-charts';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CompanyDisplayComponent } from './pages/settings/settings-company/company-display/company-display.component';
import { RoleSelectorComponent } from './common/role-selector/role-selector.component';
import { SettingsInvitationsComponent } from './pages/invitations/settings-invitations.component';
import { CompanyFormComponent } from './pages/settings/settings-company/company-form-component/company-form.component';
import { EntryComponent } from './pages/entry/entry.component';
import { InvitationComponent } from './pages/invitations/invitation/invitation.component';
import { OrganizationPageComponent } from './common/page/organization-page/organization-page.component';
import { NoAccessCompanyComponent } from './pages/no-access-company/no-access-company.component';
import { OrganizationComponent } from './common/banner/organization/organization.component';
import { OrganizationsComponent } from './common/organizations/organizations.component';
import { EnumDisplayPipe } from './pipes/enum.display.pipe';
import { UserOrganizationsComponent } from './pages/user-organizations/user-organizations.component';
import { OrganizationPanelComponent } from './common/organizations/organization-panel/organization-panel.component';
import { RegisterComponent } from './pages/register/register.component';
import { NewlineToBrPipe } from './pipes/newline.to.br.pipe';
import { UsersComponent } from './pages/users/users.component';
import { SettingsOrganizationComponent } from './pages/settings/settings-organization/settings-organization.component';
import { ResumeDeleteComponent } from './pages/resumes/resume-delete/resume-delete.component';
import { EnumToArrayPipe } from './pipes/enum.to.array.pipe';
import { FilterPanelComponent } from './common/filter-panel/filter-panel.component';
import { RadioComponent } from './common/radio/radio.component';
import { ResumeContentLinksComponent } from './pages/resumes/resume/resume-content/resume-content-links/resume-content-links.component';
import { UserOrganizationFormComponent } from './pages/user-organizations/user-organization-form/user-organization-form.component';
import { EmptyListComponent } from './common/empty-list/empty-list.component';
import { JobDescriptionComponent } from './pages/jobs/job/job-pages/job-description/job-description.component';
import { JobDescriptionRequirementsComponent } from './pages/jobs/job/job-pages/job-description/job-description-requirements/job-description-requirements.component';
import { JobDeleteComponent } from './pages/jobs/job-delete/job-delete.component';
import { ResumeSelectorComponent } from './common/resume-selector/resume-selector.component';
import { ResumeSelectorPanelComponent } from './common/resume-selector/resume-selector-panel/resume-selector-panel.component';
import { JobApplicantSelectorComponent } from './pages/jobs/job/job-applicants-selector/job-applicant-selector.component';
import { JobApplicantPanelComponent } from './pages/jobs/job/job-pages/job-applicants/job-applicant-panel/job-applicant-panel.component';
import { FilterApplicantsByGroupPipe } from './pipes/filter.applicants.by.group.pipe';
import { JobMatchLanguagesComponent } from './pages/jobs/job/job-applicant/job-applicant-match/job-match-languages/job-match-languages.component';
import { JobMatchLicencesAndCertificationsComponent } from './pages/jobs/job/job-applicant/job-applicant-match/job-match-licences-and-certifications/job-match-licences-and-certifications.component';
import { JobMatchOptionalComponent } from './pages/jobs/job/job-applicant/job-applicant-match/job-match-optional/job-match-optional.component';
import { SkeletonLoaderComponent } from './common/skeleton-loader/skeleton-loader.component';
import { UserPanelComponent } from './pages/users/user-panel/user-panel.component';
import { JobMatchSectionComponent } from './pages/jobs/job/job-applicant/job-applicant-match/job-match-section/job-match-section.component';
import { InvitationCreateComponent } from './pages/users/invitation-create/invitation-create.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { TagComponent } from './common/tag/tag.component';
import { ProblemReportButtonComponent } from './common/banner/problem-report-button/problem-report-button.component';
import { ProblemReportComponent } from './common/problem-report/problem-report.component';
import { InvitationPanelComponent } from './pages/user-organizations/invitation-panel/invitation-panel.component';
import { JobBannerComponent } from './pages/jobs/job/job-banner/job-banner.component';
import { JobMenuComponent } from './pages/jobs/job/job-banner/job-menu/job-menu.component';
import { JobApplicantsComponent } from './pages/jobs/job/job-pages/job-applicants/job-applicants.component';
import { ApplicantComponent } from './pages/jobs/job/job-pages/job-applicants/applicant/applicant.component';
import { ApplicantNotesComponent } from './pages/jobs/job/job-pages/job-applicants/applicant/applicant-notes/applicant-notes.component';
import { ApplicantNoteComponent } from './pages/jobs/job/job-pages/job-applicants/applicant/applicant-notes/applicant-note/applicant-note.component';
import { ApplicantResumeComponent } from './pages/jobs/job/job-pages/job-applicants/applicant/applicant-resume/applicant-resume.component';
import { PipelineComponent } from './pages/jobs/job/job-pages/pipeline/pipeline.component';
import { PipelineStageComponent } from './pages/jobs/job/job-pages/pipeline/pipeline-stage/pipeline-stage.component';
import { PipelineStageApplicantComponent } from './pages/jobs/job/job-pages/pipeline/pipeline-stage/pipeline-stage-applicant/pipeline-stage-applicant.component';
import { ResumeImageComponent } from './common/resume-image/resume-image.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ApplicantNoteDeleteComponent } from './pages/jobs/job/job-pages/job-applicants/applicant/applicant-notes/applicant-note-delete/applicant-note-delete.component';
import { ApplicantDeleteComponent } from './pages/jobs/job/job-pages/job-applicants/applicant/applicant-delete/applicant-delete.component';
import { ApplicantEvaluationComponent } from './pages/jobs/job/job-pages/job-applicants/applicant/applicant-evaluation/applicant-evaluation.component';
import { ApplicantEvaluationInfoComponent } from './pages/jobs/job/job-pages/job-applicants/applicant/applicant-evaluation/applicant-evaluation-info/applicant-evaluation-info.component';
import { ApplicantEvaluationRequirementsComponent } from './pages/jobs/job/job-pages/job-applicants/applicant/applicant-evaluation/applicant-evaluation-requirements/applicant-evaluation-requirements.component';
import { ApplicantEvaluationConcernsComponent } from './pages/jobs/job/job-pages/job-applicants/applicant/applicant-evaluation/applicant-evaluation-concerns/applicant-evaluation-concerns.component';
import { OrderByPipe } from './pipes/order.by.pipe';
import { ApplicantEvaluationScoresComponent } from './pages/jobs/job/job-pages/job-applicants/applicant/applicant-evaluation/applicant-evaluation-scores/applicant-evaluation-scores.component';
import { ApplicantEvaluationScoreComponent } from './pages/jobs/job/job-pages/job-applicants/applicant/applicant-evaluation/applicant-evaluation-scores/applicant-evaluation-score/applicant-evaluation-score.component';
import { ApplicantEvaluationRadarComponent } from './pages/jobs/job/job-pages/job-applicants/applicant/applicant-evaluation/applicant-evaluation-scores/applicant-evaluation-radar/applicant-evaluation-radar.component';
import { ResumeContentReferencesComponent } from './pages/resumes/resume/resume-content/resume-content-references/resume-content-references.component';
import { ResumeOverviewEmploymentComponent } from './pages/resumes/resume/resume-overview/resume-overview-employment/resume-overview-employment.component';
import { ApplicantRatingsComponent } from './pages/jobs/job/job-pages/job-applicants/applicant/applicant-ratings/applicant-ratings.component';
import { ApplicantRatingFormComponent } from './pages/jobs/job/job-pages/job-applicants/applicant/applicant-ratings/applicant-rating-form/applicant-rating-form.component';
import { ApplicantRatingPanelComponent } from './pages/jobs/job/job-pages/job-applicants/applicant/applicant-ratings/applicant-rating-panel/applicant-rating-panel.component';
import { ApplicantRatingDeleteComponent } from './pages/jobs/job/job-pages/job-applicants/applicant/applicant-ratings/applicant-rating-delete/applicant-rating-delete.component';
import { PipelineApplicantComponent } from './pages/jobs/job/job-pages/pipeline/pipeline-applicant/pipeline-applicant.component';
import { Router } from '@angular/router';
import { ObjectToArrayPipe } from './pipes/object.to.array.pipe';
import { ExistingOrUploadSelectorComponent } from './pages/jobs/job/job-banner/existing-or-upload-selector/existing-or-upload-selector.component';
import { JobApplicantUploadsComponent } from './pages/jobs/job/job-pages/job-applicants/job-applicant-uploads/job-applicant-uploads.component';
import { JobAiInstructionsComponent } from './pages/jobs/job/job-ai-instructions/job-ai-instructions.component';
import { CdkFixedSizeVirtualScroll, CdkVirtualForOf, CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { CheckoutsSuccessComponent } from './pages/checkouts/checkouts-success/checkouts-success.component';
import { SubscriptionPlanComponent } from './pages/subscription-plan/subscription-plan.component';
import { CheckoutsCancelComponent } from './pages/checkouts/checkouts-cancel/checkouts-cancel.component';
import { MatSliderModule } from '@angular/material/slider';
import { appCheckInstance$ } from '@angular/fire/app-check';

@NgModule({
  declarations: [
    AppComponent,
    ContentComponent,
    PageComponent,
    BannerComponent,
    LogoComponent,
    MainMenuComponent,
    ProfileComponent,
    UploadButtonComponent,
    LoginComponent,
    ResumesComponent,
    EmailVerificationComponent,
    CreditsComponent,
    JobsComponent,
    SettingsComponent,
    LoaderComponent,
    UserFormComponent,
    UserDeleteComponent,
    SettingsMenuComponent,
    SettingsCompaniesComponent,
    SettingsAccountComponent,
    CompanySubscriptionComponent,
    SubscriptionComponent,
    JobFormComponent,
    JobPanelComponent,
    JobComponent,
    JobApplicantComponent,
    JobApplicantPanelComponent,
    JobApplicantCategoryComponent,
    ProgressBarComponent,
    UploadComponent,
    UploadFileComponent,
    ResumePanelComponent,
    CheckboxComponent,
    JobApplicantMatchComponent,
    JobMatchSkillsComponent,
    JobMatchExperienceComponent,
    JobMatchEducationComponent,
    ResumeComponent,
    ResumeInfoComponent,
    ResumeContentComponent,
    ResumeInsightsComponent,
    RateBarComponent,
    ResumeOverviewComponent,
    ResumeOverviewExperienceComponent,
    ResumeOverviewEducationComponent,
    ResumeOverviewTopSkillsComponent,
    ResumeOverviewGapsComponent,
    ResumeOverviewCareerComponent,
    ProgressBarSegmentedComponent,
    IndicatorInfoComponent,
    LevelInfoComponent,
    ResumeContentExperienceComponent,
    ResumeContentEducationComponent,
    ResumeContentSkillsComponent,
    ResumeContentLanguagesComponent,
    ResumeContentLicensesAndCertificationsComponent,
    ResumeContentAwardsAndAchievementsComponent,
    ResumeContentProjectsComponent,
    ResumeContentAboutComponent,
    ResumeContentHobbiesAndInterestsComponent,
    ResumeContentPublicationsComponent,
    ResumeContentPresentationsAndConferencesComponent,
    ResumeOverviewRadarComponent,
    CompanyDisplayComponent,
    RoleSelectorComponent,
    CompanyFormComponent,
    EntryComponent,
    SettingsInvitationsComponent,
    InvitationComponent,
    OrganizationPageComponent,
    NoAccessCompanyComponent,
    OrganizationComponent,
    OrganizationsComponent,
    EnumDisplayPipe,
    UserOrganizationsComponent,
    OrganizationPanelComponent,
    RegisterComponent,
    NewlineToBrPipe,
    UsersComponent,
    SettingsOrganizationComponent,
    ResumeDeleteComponent,
    EnumToArrayPipe,
    FilterPanelComponent,
    RadioComponent,
    ResumeContentLinksComponent,
    UserOrganizationFormComponent,
    EmptyListComponent,
    JobDescriptionComponent,
    JobDescriptionRequirementsComponent,
    JobDeleteComponent,
    ResumeSelectorComponent,
    ResumeSelectorPanelComponent,
    JobApplicantSelectorComponent,
    FilterApplicantsByGroupPipe,
    JobMatchLanguagesComponent,
    JobMatchLicencesAndCertificationsComponent,
    JobMatchOptionalComponent,
    SkeletonLoaderComponent,
    UserPanelComponent,
    JobMatchSectionComponent,
    InvitationCreateComponent,
    ForgotPasswordComponent,
    TagComponent,
    ProblemReportButtonComponent,
    ProblemReportComponent,
    InvitationPanelComponent,
    JobBannerComponent,
    JobMenuComponent,
    JobApplicantsComponent,
    ApplicantComponent,
    ApplicantNotesComponent,
    ApplicantNoteComponent,
    ApplicantResumeComponent,
    PipelineComponent,
    PipelineStageComponent,
    PipelineStageApplicantComponent,
    ResumeImageComponent,
    ApplicantNoteDeleteComponent,
    ApplicantDeleteComponent,
    ApplicantEvaluationComponent,
    ApplicantEvaluationInfoComponent,
    ApplicantEvaluationRequirementsComponent,
    ApplicantEvaluationConcernsComponent,
    OrderByPipe,
    ApplicantEvaluationScoresComponent,
    ApplicantEvaluationScoreComponent,
    ApplicantEvaluationRadarComponent,
    ResumeContentReferencesComponent,
    ResumeOverviewEmploymentComponent,
    ApplicantRatingsComponent,
    ApplicantRatingFormComponent,
    ApplicantRatingPanelComponent,
    ApplicantRatingDeleteComponent,
    PipelineApplicantComponent,
    ObjectToArrayPipe,
    ExistingOrUploadSelectorComponent,
    JobApplicantUploadsComponent,
    JobAiInstructionsComponent,
    CheckoutsSuccessComponent,
    CheckoutsCancelComponent,
    SubscriptionPlanComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    MatDialogModule,
    ReactiveFormsModule,
    DragDropModule,
    provideFirebaseApp(() => {
      if (isDevMode() && environment.localEmulators) {
        console.warn('This app will connect to local Firebase Emulators...');
      } else {
        console.log('This app will connect to the production Firebase instance...'); // TODO remove at some point
      }

      return initializeApp(environment.firebase);
    }),
    provideAuth(() => getAuth()),
    provideFirestore(() => {
      const firestore = getFirestore();

      if (isDevMode() && environment.localEmulators) {
        console.warn('Connecting to local Firestore Emulator ...');
        connectFirestoreEmulator(firestore, 'localhost', 8080);
      } else {
        console.log('This app will connect to the production Firestore instance...'); // TODO remove at some point
      }

      return firestore;
    }),
    provideFunctions(() => {
      const functions = getFunctions(undefined, 'europe-west3');

      if (isDevMode() && environment.localEmulators) {
        console.warn('Connecting to local Functions Emulator ...');
        connectFunctionsEmulator(functions, 'localhost', 5001);
      } else {
        console.log('This app will connect to the production Functions instance...'); // TODO remove at some point
      }

      return functions;
    }),
    provideStorage(() => getStorage()),
    NgOptimizedImage,
    NgChartsModule,
    MatProgressSpinnerModule,
    CdkVirtualScrollViewport,
    CdkFixedSizeVirtualScroll,
    CdkVirtualForOf,
    MatSliderModule
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false
      })
    },
    {
      provide: Sentry.TraceService,
      deps: [Router]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
