export enum FirebaseCollectionNames {
  TRIGGERS = "triggers",
  EVENTS = "events",
  USERS = "users",
  ORGANIZATIONS = "organizations",
  JOBS = "jobs",
  RESUMES = "resumes",
  APPLICANTS = "applicants",
  INVITATIONS = "invitations",
  PROBLEM_REPORTS = "problemReports",
  RESUME_TAGS = "resumeTags",
  JOB_TAGS = "jobTags",
  CREDITS = "credits",
}

export enum FirebaseStripeCollectionNames {
  CUSTOMERS = "customers",
  UPDATE_SUBSCRIPTION = "update_subscription_sessions",
  PRODUCTS = "products", // This needs to match the collection name in the Stripe Firebase extension
  CONFIGURATION = "configuration", // This needs to match the collection name in the Stripe Firebase extension
}

export enum FirebaseStorageFolderNames {
  ORGANIZATIONS = "organizations",
  RESUMES = "resumes",
  JOBS = "jobs",
  IMAGES = "images",
}
