import { Component } from '@angular/core';

interface IMenuPage {
  name: string;
  url: string;
}
@Component({
  selector: 'main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.css'],
})
export class MainMenuComponent {
  pages: IMenuPage[] = [
    { name: 'resumes', url: 'resumes' },
    { name: 'jobs', url: 'jobs' },
    { name: 'users', url: 'users' },
    { name: 'settings', url: 'settings' },
  ];
}
