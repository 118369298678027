import { Injectable } from "@angular/core";
import {
  addDoc, collection, collectionData, doc, docData, Firestore, getDocs, orderBy, query, where, writeBatch
} from "@angular/fire/firestore";
import { FirebaseCollectionNames } from "../../../../model/FirebaseCollectionNames";
import { IJob } from "../../../../model/IJob";
import { StoreService } from "../services/store.service";
import { Api } from "./api";
import {FirestoreFilter} from "../model/firestore.filter";
import {FirestoreSorting} from "../model/firestore.sorting";
import {QueryConstraint} from "@firebase/firestore";
import {IResume} from "../../../../model/IResume";
import {ICredits} from "../../../../model/IOrganization";

@Injectable({
  providedIn: "root",
})
export class CreditsApi extends Api<ICredits> {
  constructor(
    private firestore: Firestore,
    private store: StoreService,
  ) {
    super();
  }

  getOne(id: string) {
    const reference = doc(this.firestore, this.getPath(), id);
    return docData<ICredits>(reference as any, this.options);
  }

  getAll(filters: FirestoreFilter[] = [], sorting: FirestoreSorting[] = []) {
    const constraints: QueryConstraint[] = [
      ...filters.map( (filter) => where(filter.field, filter.condition, filter.value) ),
      ...sorting.map( (sortCondition) => orderBy(sortCondition.field, sortCondition.order) ),
    ];
    const reference = query(collection(this.firestore, this.getPath()), ...constraints);
    return collectionData<ICredits>(reference as any, this.options);
  }



  private getPath() {
    return (
      FirebaseCollectionNames.ORGANIZATIONS + "/" + this.store.organizationId! + "/" + FirebaseCollectionNames.CREDITS
    );
  }
}
