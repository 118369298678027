<a class="row center-left flex" [routerLink]="'subscription'">
  <div id="credits" class="banner-button row row-center-left">
    <div id="credits-icon" class="banner-button-icon">
      <img src="assets/images/credits.svg" />
    </div>
    <div class="banner-button-content column column-center-left">
      <div id="credits-content" class="column">
        <div id="credits-label" class="">Credits</div>
        <div id="credits-value" class="">
          {{ availableCredits }} / {{ totalCredits }}
        </div>
      </div>
    </div>
  </div>
</a>
