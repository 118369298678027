import { IApplicant } from '../../../../model/IApplicant';

export interface IApplicantGroup {
  type: EApplicantGroupType;
  applicants: IApplicant[];
}

export interface ICategoryDefinition {
  type: EApplicantGroupType;
  label: string;
  icon: string;
  styleClass: string;
  percentageAbove: number;
}

export enum EApplicantGroupType {
  PerfectFit,
  GreatFit,
  GoodFit,
  PotentialFit,
  NotAFit,
}
