import { Injectable } from "@angular/core";
import {
  collection, collectionData, doc, docData, Firestore, getDocs, query, runTransaction, where, writeBatch
} from "@angular/fire/firestore";
import { FirebaseCollectionNames } from "../../../../model/FirebaseCollectionNames";
import { StoreService } from "../services/store.service";
import { IApplicant } from "../../../../model/IApplicant";
import { Observable } from "rxjs";
import { Api } from "./api";
import {IInvitation} from "../../../../model/IInvitation";
import {IOrganizationUser} from "../../../../model/IOrganizationUser";
import {IUser} from "../../../../model/IUser";

@Injectable({
  providedIn: "root",
})
export class TransactionsApi {

  options: object = { idField: "id" };

  constructor(private firestore: Firestore, private store: StoreService) {

  }

  async deleteOrganizationUser(organizationUser: IOrganizationUser): Promise<void> {

    try {
      await runTransaction(this.firestore, async (transaction) => {
        // Set the references
        const organizationUserReference = doc(collection(this.firestore, this.getOrganizationUsersPath(this.store.organizationId!)), organizationUser.id);

        // Get the user and check the active organization and their organizations array for occurrences of this.store.organization
        if(organizationUser.userId) {
          const userReference = doc(collection(this.firestore, this.getUsersPath()), organizationUser.userId);
          const userSnapshot = await transaction.get(userReference);
          if (userSnapshot.exists()) {
            const user: IUser = userSnapshot.data() as IUser;
            if (user.favoriteOrganization === this.store.organizationId!) {
              user.favoriteOrganization = undefined;
            }
            user.organizations = user.organizations.filter(organization => organization !== this.store.organizationId!);
            transaction.update(userReference, {...user});
          }
          }
        // TODO We need to delete the invitation
        transaction.delete(organizationUserReference);
      });
      console.log("Transaction successfully committed!");
    } catch (e) {
      console.error("Transaction failed: ", e);
    }
  }


  async inviteUser(invitation: Partial<IInvitation>, organizationUser: Partial<IOrganizationUser> ): Promise<void> {

    try {
      await runTransaction(this.firestore, async (transaction) => {
        // Create the organization user
        const organizationUserReference = doc(collection(this.firestore, this.getOrganizationUsersPath(this.store.organizationId!)));
        transaction.set(organizationUserReference, organizationUser, { merge: true });

        // Create the invitation using the id from the newly created organization user
        const invitationReference = doc(collection(this.firestore, this.getInvitationsPath()));
        transaction.set(invitationReference, {...invitation, organizationUserId: organizationUserReference.id });

      });
      console.log("Transaction successfully committed!");
    } catch (e) {
      console.error("Transaction failed: ", e);
    }
  }


  async acceptInvitation(userId: string, user: Partial<IUser>, invitation: Partial<IInvitation>, organizationUser: Partial<IOrganizationUser>) {

    try {
      await runTransaction(this.firestore, async (transaction) => {
        // References for the documents to be updated
        const userReference = doc(collection(this.firestore, this.getUsersPath()), userId);
        const organizationUserReference = doc(collection(this.firestore, this.getOrganizationUsersPath(invitation.organizationId!)), invitation.organizationUserId);
        const invitationReference = doc( collection( this.firestore, this.getInvitationsPath() ), invitation.id);

        transaction.set(userReference, user, { merge: true });
        transaction.set(organizationUserReference, organizationUser, { merge: true });
        transaction.delete(invitationReference);
      });
      console.log("Transaction successfully committed!");
    } catch (e) {
      console.error("Transaction failed: ", e);
    }
  }


  private getUsersPath() {
    return FirebaseCollectionNames.USERS;
  }
  private getInvitationsPath() {
    return FirebaseCollectionNames.INVITATIONS;
  }
  private getOrganizationUsersPath(organizationId: string) {
    return (FirebaseCollectionNames.ORGANIZATIONS + "/" + organizationId + "/" + FirebaseCollectionNames.USERS);
  }
}
