<ng-container *ngIf="applicant$ | async as applicant">
  <div class="applicant-wrapper column fill-space overflow-hidden">
    <div class="applicant overflow-hidden column fill-space">
      <div class="title-applicant"> {{ applicant.info?.name }} </div>
      <div class="applicant-menu-wrapper">
        <div class="applicant-menu fill-space row-center-left">
          <div class="applicant-menu-item" routerLinkActive="applicant-menu-item-selected" routerLink="ai-evaluation" > AI Evaluation Report </div>
          <div class="applicant-menu-item" routerLinkActive="applicant-menu-item-selected" routerLink="ai-resume"> Resume </div>
          <div class="applicant-menu-item" routerLinkActive="applicant-menu-item-selected" routerLink="notes"> Notes </div>
          <div class="applicant-menu-item" routerLinkActive="applicant-menu-item-selected" routerLink="ratings"> Reviews </div>
        </div>
        <div class="center">
          <div class="header-button" (click)="showDeleteApplicant(applicant)">
            <div class="header-button-icon center">
              <img src="assets/images/delete.svg"/>
            </div>
            <div class="header-button-label center">Remove From Job</div>
          </div>
        </div>
      </div>
      <router-outlet></router-outlet>
    </div>
  </div>
</ng-container>
