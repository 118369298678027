import {Component} from "@angular/core";
import {MatDialogRef} from "@angular/material/dialog";
import {OrganizationInvitationsService} from "../../../services/organization-invitations.service";
import {StoreService} from "../../../services/store.service";
import {UserFormComponent} from "../user-form/user-form.component";
import {EUserRole} from "../../../../../../model/enums/EUserRole";
import {IInvitation} from "../../../../../../model/IInvitation";
import {OrganizationService} from "../../../services/organization.service";
import {IOrganization} from "../../../../../../model/IOrganization";
import {TransactionsApi} from "../../../api/transactions.api";
import {IOrganizationUser} from "../../../../../../model/IOrganizationUser";
import {EInvitationStatus} from "../../../../../../model/enums/EInvitationStatus";
import {InvitationsApi} from "../../../api/invitations";

@Component({
  selector: "invitation-create",
  templateUrl: "./invitation-create.component.html",
  styleUrls: ["./invitation-create.component.css"],
})
export class InvitationCreateComponent {
  isLoading: boolean = false;
  email: string | undefined;
  role: EUserRole = EUserRole.MANAGER;

  constructor(
    private dialogRef: MatDialogRef<UserFormComponent>,
    private transactionsApi: TransactionsApi,
    private organizationInvitationsService: OrganizationInvitationsService,
    public store: StoreService,
    private invitationsApi: InvitationsApi
  ) {}

  /*inviteUser(): void {
    if (!this.email || !this.role) {
      return;
    }

    this.isLoading = true;

    const invitationData: InvitationData = {
      invitedByUserId: this.auth.currentUser?.uid!,
      invitedByEmail: this.auth.currentUser?.email ?? "system@hirefox.ai",
      inviteForUserEmail: this.email,
      role: this.role,
      organizationId: this.store.organizationId!,
    };

    this.organizationInvitationsService
      .create(invitationData)
      .then((response) => {
        console.log(response);
        this.dialogRef.close();
      })
      .catch((error) => {
        console.error(error);
        this.isLoading = false;
      });
  }*/
  inviteUser(organization: IOrganization): void {
    if (!this.email || !this.role || !organization) {
      return;
    }

    const invitation: Partial<IInvitation> = {
      userEmail: this.email,
      organizationId: organization.id,
      organizationName: organization.name,
      creationTime: this.invitationsApi.getServerTimestamp()
    };

    const organizationUser: Omit<IOrganizationUser, 'id' | 'name' | 'userId'> = {
      email: this.email,
      role: this.role,
      status: EInvitationStatus.PENDING,
      creationTime: this.invitationsApi.getServerTimestamp()
    }

    this.isLoading = true;
    this.transactionsApi.inviteUser(invitation, organizationUser).then((response) => {
      this.dialogRef.close();
    }).catch((error) => {
      console.error(error);
    }).finally( () => {
      this.isLoading = false;
    });
  }


  setRole(role: EUserRole) {
    this.role = role;
  }

  protected readonly EUserRole = EUserRole;
}

